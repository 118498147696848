import Layout2 from '@/layout/Layout2'

export default [{
  path: '/webstore',
  component: Layout2,
  children: [
    {
      path: 'catalogo/',
      component: () => import('@/components/webstore/catalogo')
    },
    {
      path: 'login/',
      component: () => import('@/components/webstore/login')
    },
    {
      path: 'articuloDetalle/:id',
      component: () => import('@/components/webstore/articuloDetalle')
    },
    {
      path: 'carrito/',
      component: () => import('@/components/webstore/carrito')
    },
    {
      path: 'pagoExitoso/',
      component: () => import('@/components/webstore/pagoExitoso')
    },
    {
      path: 'pagoPendiente/',
      component: () => import('@/components/webstore/pagoPendiente')
    },
    {
      path: 'misFavoritos/',
      component: () => import('@/components/webstore/misFavoritos')
    },
    {
      path: 'misCompras/',
      component: () => import('@/components/webstore/misCompras')
    },
    {
      path: 'inicio/',
      component: () => import('@/components/webstore/inicio')
    },
    {
      path: 'notaVenta/',
      component: () => import('@/components/webstore/notaVenta')
    },
    {
      path: 'preguntas/',
      component: () => import('@/components/webstore/preguntas')
    },
    {
      path: 'validarCorreo/',
      component: () => import('@/components/webstore/validarCorreo')
    },
    {
      path: 'conocenos/',
      component: () => import('@/components/webstore/conocenos')
    },
    {
      path: 'politicaPrivacidad/',
      component: () => import('@/components/webstore/politicaPrivacidad')
    },
    {
      path: 'condicionesServicio/',
      component: () => import('@/components/webstore/condicionesServicio')
    },
    {
      path: 'restablecerCorreo/',
      component: () => import('@/components/webstore/restablecerCorreo')
    },
    {
      path: 'misOfertas/',
      component: () => import('@/components/webstore/misOfertas')
    }]
}]
