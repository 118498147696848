<template>
  <nav class="footer bg-dark text-white" :class="getLayoutFooterBg()">
    <br>
    <!-- SECCIONES PIE DE PAGINA -->
    <b-row style="margin: 10px;">
      <b-col>
        <div class="container ctrlCentrado" style="background-color: #2f3337;">
          <h5>{{ jsonIdioma.piePagina.redesSociales }}</h5>
          <b-button-group>
            <b-button variant="outline-secondary" @click="linkFacebook()">
              <b-img src='/img/facebook_logo.png' class="img-thumb-gallery" style="width: 24px; height: 24px; float: left; margin-right: 5px;"/>
            </b-button>
            <b-button variant="outline-secondary" @click="linkInstagram()">
              <b-img src='/img/Insta.png' class="img-thumb-gallery" style="width: 24px; height: 24px; float: left; margin-right: 5px;"/>
            </b-button>
          </b-button-group>
          <h5>Neptune Baazar®</h5>
          <b-button variant="outline-primary" @click.prevent="$router.push('/webstore/conocenos/')">
            {{ jsonIdioma.menu.conocenos }}
          </b-button>
          <!--
          <p>{{ jsonIdioma.piePagina.sucursal }} De las Américas</p>
          <p>327 Calz. de las Américas, Mexicali</p>
          -->
          <p>#DONDEMAS</p>
          <!--
          <b-link
            href="mailto:contacto@neptunebaazar.com"
            :title=jsonIdioma.piePagina.enviarEmail>
            contacto@neptunebaazar.com
          </b-link>
          -->
          <b-link @click.prevent="$router.push('/webstore/politicaPrivacidad/')">
            {{ jsonIdioma.politicaPrivacidad.politicaPrivacidad }}
          </b-link> &nbsp;  |  &nbsp;
          <b-link @click.prevent="$router.push('/webstore/condicionesServicio/')">
            {{ jsonIdioma.condicionesServicio.condicionesServicio }}
          </b-link>
          <!--
          <p>{{ jsonIdioma.piePagina.sucursal }} Gonzalez Ortega</p>
          <p>Rio Sta Cruz 2627-5, Nuevo Mexicali, 21399 Mexicali, B.C.</p>
          <p>#DONDEMAS</p>
          -->
        </div>
      </b-col>
    </b-row>

    <div class="container-fluid d-flex flex-wrap justify-content-between text-center container-p-x pb-3">
      <div>
        <span class="footer-text font-weight-bolder">Neptune Baazar</span> ©,
        {{ jsonIdioma.piePagina.desarrolladoPor }}
          DelfinSoftware
      </div>
    </div>
  </nav>
</template>
<style>
  .ctrlCentrado {
    width: 100%;
    text-align: center;
  }
</style>
<script>
import espTraduccion from '../locales/esp.json'
import ingTraduccion from '../locales/ing.json'

export default {
  name: 'app-layout-footer',
  data: () => ({
    idiomaSelec: 'ing',
    jsonIdioma: {}
  }),
  created () {
    var self = this
    // TRADUCCION INTERFAZ
    self.idiomaSelec = window.$cookies.get('idiomaSelec')
    self.idiomaSelec = self.idiomaSelec === null ? 'esp' : self.idiomaSelec
    self.traduccion()
  },
  methods: {
    traduccion () {
      var self = this
      if (self.idiomaSelec === 'esp') {
        self.jsonIdioma = espTraduccion
      }
      if (self.idiomaSelec === 'ing') {
        self.jsonIdioma = ingTraduccion
      }
    },
    getLayoutFooterBg () {
      return `bg-${this.layoutFooterBg}`
    },
    linkInstagram () {
      window.open('https://www.instagram.com/baazarneptune/')
    },
    linkFacebook () {
      window.open('https://www.facebook.com/baazarneptune')
    }
  }
}
</script>
